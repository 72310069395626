.c132-user .ant-layout-content {
  text-align: center;
}
.c132-user .m-tabBox__list__item {
  width: 25%;
}
.c132-user .m-tabBox {
  max-width: 800px;
  margin: 0 auto;
}
.c132-user .m-tabBox__list__item a {
  font-size: 18px;
  display: block;
  padding: 0.5em 1em;
  border-bottom-width: 0;
  pointer-events: none;
  color: #1d8f88;
  border: solid 1px #3333;
  border-bottom-color: transparent;
}
.c132-user .m-tabBox__list [aria-selected="false"] {
  pointer-events: auto;
  border-color: transparent;
  border-bottom: solid 1px #3333;
  transition-duration: 0.2s;
  transition-property: border, background-color;
}
.c132-user .cropper-preview {
  border-radius: 50%;
}
.c132-user .delete_order {
  margin-top: 15px;
  font-size: 15px;
  cursor: pointer;
}
/** 授权书的样式 */
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.container {
  max-width: 790px;
}
.my-container {
  z-index: -99;
  position: fixed;
  top: 0px;
  background-color: #fff;
  color: #000;
}
.padding-60 {
  padding: 60px !important;
  padding-top: 20px !important;
}
.padding-bottom-120 {
  padding-bottom: 120px !important;
}
.text-center {
  text-align: center;
}
.text-ti {
  font-size: 22px;
}
.text {
  font-size: 18px;
  line-height: 40px;
  text-indent: 2em;
  margin-top: 20px;
}
.text span {
  padding: 0px 10px;
  border-bottom: solid 1px #000;
}
.size-18 {
  font-size: 18px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.my-table {
  font-size: 16px;
  margin-top: 15px;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
td,
th {
  display: table-cell;
  vertical-align: inherit;
}
.table > tbody > tr > td {
  padding: 10px;
  line-height: 1.42857143;
  vertical-align: top;
}
.table-bordered td {
  border: dashed 1px #c8c8c8 !important;
}
.table-bordered tbody tr td:first-child {
  width: 140px !important;
}
.table-bordered tbody tr td:last-child {
  width: 447px;
  word-break: break-all !important;
  word-wrap: break-word;
}
.table-bordered {
  width: 100%;
  border: dashed 1px #c8c8c8;
}
.text-right {
  text-align: right;
}
.line50 {
  line-height: 50px;
}
.po-re {
  position: relative;
}
.time span {
  padding: 0 10px;
}
.po-ab {
  position: absolute;
}
.post {
  top: 20px;
  right: 0px;
  opacity: 0.9;
}
.nashui {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #3333;
}
.buyRecordBox__title {
  width: 300px;
}
/*  我的会员  */
.no-bg {
  background: none !important;
}
.main-person {
  overflow: hidden;
}
.main-person-left {
  width: 20%;
  float: left;
}
.main-person-logo {
  padding: 10%;
  position: relative;
}
.main-person-logo img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
}
.main-person-logo span {
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 30%;
}
.main-person-logo span img {
  width: 100%;
  height: auto;
}
.main-person-right {
  width: 80%;
  float: left;
}
.person-detail-left {
  width: calc(40% - 30px);
  float: left;
  padding-right: 30px;
}
.person-detail-ti {
  font-size: 13pt;
  color: #05312f;
  padding-bottom: 20px;
}
.person-detail-ti span {
  font-size: 14;
  width: 50px;
  line-height: 22px;
  background: #999999;
  border-radius: 3.6px;
  text-align: center;
  display: inline-block;
  float: right;
  color: #cccccc;
}
.person-detail-pay a {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #00a99d;
  line-height: 40px;
  background: #05312f;
  border-radius: 8px;
  display: block;
  text-align: center;
}
.person-detail-right {
  width: calc(60%-22px);
  width: -webkit-calc(38%);
  width: -moz-calc(38%);
  width: 57%;
  float: left;
  padding-left: 20px;
  border-left: solid 2px #074443;
}
.detail-right-all {
  overflow: hidden;
}
.detail-right-all div {
  width: 50%;
  float: left;
  text-align: center;
}
.detail-right-all div p {
  font-size: 28px;
  color: #00a99d;
  line-height: 40px;
}
.detail-right-all div p img {
  margin-right: 10px;
}
.detail-right-all div a {
  font-size: 12px;
  line-height: 40px;
  color: #05312f;
}
.detail-right-else {
  font-size: 12px;
  line-height: 20px;
  color: red;
}
.detail-right-else img {
  margin: 3px 10px 3px 0px;
}
.person-detail-right-an {
  border-left: solid 2px #B7B7B7;
}
@media (max-width: 768px) {
  .main-person-left {
    width: 100%;
  }
  .main-person-logo {
    padding: 0%;
  }
  .main-person-logo span {
    width: 10%;
    right: 35%;
    bottom: 0px;
  }
  .main-person-right {
    width: 100%;
  }
  .main-person-detail {
    margin-top: 30px;
  }
  .person-detail-left {
    width: calc(100% - 30px);
    width: -webkit-calc(70%);
    width: -moz-calc(70%);
    float: left;
    padding-right: 30px;
  }
  .person-detail-right {
    width: 100%;
    float: left;
    padding-left: 0px;
    border-left: none;
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid 2px #074443;
  }
  .person-detail-ti {
    font-size: 13px;
  }
}
