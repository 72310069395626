#apply_for_cash .cashItem__dt {
  font-weight: unset;
  width: 7em;
  padding-right: 1.2em;
  float: left;
  font-size: 16px;
  text-align: right;
}
#apply_for_cash .ant-radio-inner {
  height: 14px;
  width: 14px;
}
#apply_for_cash .ant-radio-group {
  display: flow-root;
}
#apply_for_cash .one_font_size {
  font-size: 15px;
}
#apply_for_cash .one_font_notice {
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #FF0000;
  margin-left: 24px;
  padding-right: 16px;
}
#apply_for_cash .two_font_notice {
  font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #FF0000;
  margin-left: 24px;
}
#apply_for_cash .cashItem__list {
  width: 100%;
}
#apply_for_cash .margin_left_44 {
  margin-left: 44px;
  padding-top: 2px;
}
#apply_for_cash .padding_top_2 {
  padding-top: 2px;
}
#apply_for_cash .margin_left_60 {
  margin-left: 60px;
}
#apply_for_cash .font_size_15 {
  font-size: 15px;
}
#apply_for_cash .height_27 {
  height: 27px;
  padding-left: 24px;
}
#apply_for_cash .ant-form-item-label {
  float: left;
  line-height: 30px;
  width: 7em;
  text-align: right;
  font-size: 15px;
}
#apply_for_cash .ant-form-item-control-wrapper {
  float: left;
  width: 50%;
}
#apply_for_cash .ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 32px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#apply_for_cash .clear_both {
  clear: both;
}
#apply_for_cash .padding_left_9 {
  padding-left: 9em;
  overflow: hidden;
  margin-bottom: 23px;
}
#apply_for_cash .ant-form-item-label > label::after {
  content: '：';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
#apply_for_cash .pay_button {
  line-height: 1px;
  padding: 0.38em;
  cursor: pointer;
  background: #339e9d;
  color: #fff;
  border: 0 solid #339e9d;
  outline: none;
  width: 9em;
  height: 36px;
  border-radius: 5px;
}
#apply_for_cash .button_center {
  text-align: center;
}
#apply_for_cash .font_size_18 {
  font-size: 18px;
}
#apply_for_cash .modalBox__inner {
  width: 40%;
}
#apply_for_cash .apply_for_notice {
  background-color: #fff;
  position: relative;
  font-size: 18px;
  height: 160px;
  line-height: 100px;
  color: #000;
}
#apply_for_cash .apply_for_notice img {
  position: relative;
  top: -28px;
  right: 25px;
  width: 45px;
}
#apply_for_cash .color_monery {
  color: #339e9d;
}
#apply_for_cash .mymusicBox__title {
  text-align: center;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  #apply_for_cash .cashItem__dt {
    width: 5em;
    font-size: 13px;
    padding-top: 3px;
  }
  #apply_for_cash .one_font_size {
    font-size: 13px;
  }
  #apply_for_cash .one_font_notice {
    display: block;
    font-size: 11px;
    padding-right: 0px;
  }
  #apply_for_cash .margin_left_44 {
    margin-left: 0px;
  }
  #apply_for_cash .font_size_15 {
    font-size: 13px;
  }
  #apply_for_cash .margin_left_60 {
    margin-left: 10px;
  }
  #apply_for_cash .padding_left_9 {
    padding-left: 6.5em;
  }
  #apply_for_cash .ant-form-item-label {
    font-size: 13px;
    text-align: left;
    width: 6.5em;
  }
  #apply_for_cash .ant-input {
    height: 28px;
    font-size: 12px;
    line-height: 32px;
  }
  #apply_for_cash .ant-form-item-control-wrapper {
    float: left;
    width: 165px;
  }
  #apply_for_cash .cashItem {
    padding: 2px 10px;
    margin-bottom: 0px;
  }
  #apply_for_cash .padding_left_9 {
    margin-bottom: 10px;
  }
  #apply_for_cash .phone_width {
    width: 7em;
  }
  #apply_for_cash .padding_px_3 {
    padding-top: 3px;
  }
  #apply_for_cash .modalBox__inner {
    width: 80%;
  }
  #apply_for_cash .modalForm__close {
    right: 15px;
  }
  #apply_for_cash .apply_for_notice img {
    width: 30px;
    top: -38px;
  }
  #apply_for_cash .apply_for_notice {
    font-size: 13px;
  }
  #apply_for_cash .mymusicBox__title {
    font-size: 16px;
    background-color: #f1f6f6;
    height: 32px;
    line-height: 32px;
    font-weight: 500;
  }
  #apply_for_cash .pay_button {
    width: 105px;
    height: 35px;
  }
}
@media only screen and (max-width: 767px) {
  #apply_for_cash .cashItem__dd {
    padding-left: 6.8em;
  }
  #apply_for_cash .ant-input {
    height: 32px !important;
    line-height: 10px !important;
    padding: 10px 11px !important;
  }
}
