#composition_style .compositon_width_82 {
  width: 8.2em;
}
#composition_style .compositon_width_100_109 {
  width: calc(100% - 10.9em);
}
#composition_style .buypageForm__item {
  padding: 2.5% 3.75% 3%;
}
#composition_style .u-textareaSkin {
  font-size: 14px;
}
#composition_style .add_star:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
@media only screen and (max-width: 767px) {
  #composition_style .compositon_width_100_109 {
    width: calc(100% - 8.5em);
  }
  #composition_style .u-inputSkin02 {
    font-size: 12px;
  }
  #composition_style .u-textareaSkin {
    font-size: 12px;
  }
  #composition_style .buypageForm__item {
    padding: 4% 3.75% 5%;
  }
}
#composition_style .ant-form-item-control {
  position: relative;
}
#composition_style .ant-form-item-control .ant-form-explain {
  position: absolute;
  left: 0px;
  margin: 0px;
  font-size: 12px;
}
