#c02b478-home {
  min-height: 2000px;
}
#c02b478-home .topKv__kindList__item:not(:last-child):after {
  content: "  /";
  margin-left: 10px;
}
#c02b478-home .m-btn-type2.active:after {
  border: solid 4px #fff;
}
#c02b478-home .m-columnBox[data-column='4'] .m-columnBox__item {
  text-align: center;
}
.my_top_musicimg {
  width: 200px;
  height: 200px;
  display: inline-block;
}
.my_top_musicimg span {
  height: 200px;
  width: 200px;
  display: inline-block;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.my_List__txt1 {
  text-align: center;
}
.home_img {
  width: 100%;
  padding-bottom: 100%;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bottom_line {
  border-bottom: 2px solid #042625;
  width: 30%;
  margin: 23px auto 24px;
}
.title_size_35 {
  color: #042625;
  font-size: 2rem;
  line-height: normal;
}
@media only screen and (max-width: 767px) {
  .bottom_line {
    width: 50%;
  }
  .title_size_35 {
    font-size: 22px;
  }
}
