.hidden {
  display: none !important;
  visibility: hidden !important;
}
.main,
.mainContents,
.musicContents,
.musicContents__inner,
.mypageContents,
.otherMusicList,
.resultContents {
  min-height: 500px;
}
@media only screen and (max-width: 767px) {
  .main,
  .mainContents,
  .musicContents,
  .musicContents__inner,
  .mypageContents,
  .otherMusicList,
  .resultContents {
    min-height: 200px;
  }
}
.userNav__avatar__img {
  width: 3vw !important;
  height: 3vw !important;
}
@media only screen and (max-width: 1160px) {
  .userNav__avatar__img {
    width: 6vw !important;
    height: 6vw !important;
  }
}
@media only screen and (max-width: 767px) {
  .userNav__avatar__img {
    width: 10vw !important;
    height: 10vw !important;
  }
}
@media only screen and (max-width: 1160px) {
  .avatarBox__img {
    width: 12vw !important;
    height: 12vw !important;
  }
}
@media only screen and (max-width: 767px) {
  .avatarBox__img {
    width: 20vw !important;
    height: 20vw !important;
  }
}
.text-center {
  text-align: center;
}
.fixedPlayer__title a {
  color: #fff;
}
.fixedPlayer .fixedPlayer__backBtn .backBtn,
.fixedPlayer .fixedPlayer__nextBtn .backBtn,
.fixedPlayer .fixedPlayer__backBtn .nextBtn,
.fixedPlayer .fixedPlayer__nextBtn .nextBtn {
  opacity: 0.3;
}
.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.ant-form-explain {
  color: red;
  margin: 5px;
}
