#subscribePay .ant-radio-inner::after {
  height: 10px;
  width: 10px;
}
#subscribePay .ant-radio-wrapper {
  font-size: 15px;
}
#subscribePay .notice_pay_red {
  color: #FF0000;
  font-size: 13px;
  margin: -8px 0 20px 27px;
}
#subscribePay .notice_pay_red input {
  width: 240px;
  height: 35px;
  padding-left: 20px;
}
#subscribePay .wechat_code_phone {
  display: block;
}
@media only screen and (max-width: 767px) {
  #subscribePay .notice_pay_red {
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    margin-left: 40px;
    margin-top: -23px;
  }
  #subscribePay .ant-radio-wrapper {
    margin-left: 15px;
  }
  #subscribePay .img_phone_hidden {
    display: none;
  }
  #subscribePay .wechat_code_phone {
    display: none;
  }
}
#subscribePay .dialog-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
  color: #000;
}
#subscribePay .choose-container {
  width: 660px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -360px;
  background: #fff;
  padding: 35px 20px 50px 25px;
}
#subscribePay .close-choose {
  overflow: scroll;
}
#subscribePay .close-choose a {
  float: right;
}
#subscribePay .choose-ti {
  font-size: 15px;
  line-height: 30px;
}
#subscribePay .choose-ti img {
  width: 30px;
  float: left;
  margin-right: 5px;
}
#subscribePay .choose-list {
  margin: 35px 0px 0px 0px;
  padding: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #d7d7d7;
}
#subscribePay .choose-list li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 15px;
}
#subscribePay .choose-left {
  float: left;
  width: 76px;
  font-size: 15px;
  line-height: 32px;
}
#subscribePay .choose-right {
  float: left;
  width: calc(100%-76px);
  width: -webkit-calc(24%);
  width: -moz-calc(24%);
}
#subscribePay .choose-right a {
  padding: 0px 15px;
  line-height: 30px;
  border: solid 1px #999999;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}
#subscribePay .choose-total {
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}
#subscribePay .choose-total span {
  color: #339e9d !important;
  font-weight: 600;
  font-size: 25px;
}
#subscribePay .choose-add {
  overflow: hidden;
  margin-top: 40px;
}
#subscribePay .choose-add a {
  width: 140px;
  line-height: 40px;
  display: inline-block;
  float: right;
  text-align: center;
  font-size: 14px;
  background: #339e9d;
  color: #fff;
  border-radius: 4px;
}
#subscribePay .scroll-y {
  overflow-y: hidden;
}
#subscribePay .booker-ti {
  font-size: 18px;
  text-align: center;
}
#subscribePay .book-code {
  font-size: 14px;
  padding: 25px 0px;
}
#subscribePay .book-de {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}
#subscribePay .book-de span {
  color: #ff0000;
  font-weight: 600;
}
#subscribePay .book-de a {
  color: #000;
}
#subscribePay .table-ti {
  font-size: 14px;
  margin-bottom: 20px;
}
#subscribePay .table-name {
  background: #f1f6f6;
  overflow: hidden;
}
#subscribePay .table-name div {
  float: left;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
#subscribePay .wid20 {
  width: 20%;
}
#subscribePay .wid60 {
  width: 60%;
}
#subscribePay .table-de ul {
  padding: 0px;
  margin: 0px;
}
#subscribePay .table-de ul li {
  list-style: none;
  border-bottom: solid 1px #cccccc;
  overflow: hidden;
  padding: 15px 0px;
}
#subscribePay .table-de ul li div {
  float: left;
  text-align: center;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}
#subscribePay .color-green {
  color: #339e9d;
}
#subscribePay .wid60 .table-all {
  font-size: 14px;
  line-height: 26px;
}
#subscribePay .line-78 {
  line-height: 78px;
}
#subscribePay .table-all span {
  width: 100px;
  display: inline-block;
  text-align: right;
}
#subscribePay .cancle {
  width: 160px;
  margin: 30px auto 0 auto;
  line-height: 40px;
  color: #fff;
  background: #339e9d;
  font-size: 14px;
  display: block;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
#subscribePay .book-input {
  margin-top: 40px;
}
#subscribePay .book-input ul {
  margin: 0px;
  padding: 0px;
}
#subscribePay .book-input ul li {
  list-style: none;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 20px;
}
#subscribePay .book-input ul li span {
  color: #ff0000;
}
#subscribePay .book-left {
  float: left;
  width: 170px;
  line-height: 34px;
}
#subscribePay .book-right {
  float: left;
}
#subscribePay .book-right input {
  width: 285px;
  height: 30px;
}
#subscribePay .my-sub {
  width: 230px;
  height: 40px;
  background: #339e9d;
  border-radius: 5px;
  margin: auto;
  display: inline-block;
  border: none;
  color: #fff;
}
#subscribePay .text-center {
  text-align: center;
}
@media (max-width: 768px) {
  #subscribePay .choose-container {
    width: calc(90%);
    height: 80%;
    margin-left: -45%;
  }
  #subscribePay .choose-ti {
    font-size: 14px;
    line-height: 15px;
  }
  #subscribePay .choose-right {
    width: 100%;
  }
  #subscribePay .choose-right a {
    padding: 0px 10px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  #subscribePay .choose-list {
    margin-top: 15px;
    padding-bottom: 10px;
  }
  #subscribePay .choose-list li {
    margin-bottom: 5px;
  }
  #subscribePay .choose-total {
    margin-top: 20px;
  }
  #subscribePay .choose-add {
    margin-top: 20px;
  }
  #subscribePay .table-all span {
    width: 60px;
    display: inline-block;
    text-align: right;
  }
  #subscribePay .scroll-y {
    overflow-y: scroll;
  }
  #subscribePay .book-code {
    font-size: 12px;
  }
}
#subscribeMembers .dialog-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
}
#subscribeMembers .choose-container {
  width: 730px;
  position: fixed;
  background: #fff;
  padding: 20px 20px 20px 40px;
}
#subscribeMembers .close-choose {
  overflow: hidden;
  padding-bottom: 10px;
  margin-top: -10px;
}
#subscribeMembers .close-choose a {
  float: right;
}
#subscribeMembers .choose-ti {
  font-size: 15px;
  line-height: 30px;
}
#subscribeMembers .choose-ti img {
  width: 30px;
  float: left;
  margin-right: 5px;
}
#subscribeMembers .choose-list {
  margin: 35px 0px 0px 0px;
  padding: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #d7d7d7;
}
#subscribeMembers .choose-list li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 15px;
}
#subscribeMembers .choose-left {
  float: left;
  width: 76px;
  font-size: 15px;
  line-height: 32px;
  font-weight: 600;
}
#subscribeMembers .choose-right {
  float: left;
  width: 593px;
}
#subscribeMembers .choose-right a {
  padding: 0px 15px;
  line-height: 30px;
  border: solid 1px #999999;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}
#subscribeMembers .choose-total {
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}
#subscribeMembers .choose-total span {
  color: #339e9d !important;
  font-weight: 600;
  font-size: 25px;
}
#subscribeMembers .choose-add {
  overflow: hidden;
  margin-top: 40px;
}
#subscribeMembers .choose-add a {
  width: 140px;
  line-height: 40px;
  display: inline-block;
  float: right;
  text-align: center;
  font-size: 14px;
  background: #339e9d;
  color: #fff;
  border-radius: 4px;
}
#subscribeMembers .dialog__title {
  font-size: 18px;
  text-align: center;
  color: #000;
}
#subscribeMembers .dialog__title img {
  width: 45px;
  height: 45px;
  margin-right: 20px;
  position: relative;
  top: 10px;
}
#subscribeMembers .dialog__txt {
  text-align: center;
  margin-top: 70px;
}
#subscribeMembers .dialog__txt a {
  width: 148px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  background: #fff;
  border: 1px solid #339e9d;
  color: #339e9d;
  border-radius: 4px;
}
#subscribeMembers .add_car_success {
  height: 225px;
  padding-top: 35px;
}
#subscribeMembers .margin_right_25 {
  margin-right: 70px;
}
#subscribeMembers .dialog__txt a:hover {
  background: #339e9d !important;
  color: #fff !important;
}
#subscribeMembers .notice_price {
  margin-top: 16px;
  color: #FF0000;
  margin-left: 50px;
  font-size: 13px;
}
@media only screen and (max-width: 767px) {
  #subscribeMembers .choose-container {
    padding: 20px;
  }
  #subscribeMembers .notice_price {
    font-size: 11px;
  }
  #subscribeMembers .phone_margin_top_buy {
    padding-top: 20px;
  }
  #subscribeMembers .member-buy-margin-top {
    margin-top: -5px;
  }
  #subscribeMembers .dialog__txt a {
    width: 103px;
    line-height: 30px;
    font-size: 12px;
  }
  #subscribeMembers .margin_right_25 {
    margin-right: 35px;
  }
  #subscribeMembers .dialog__title {
    font-size: 13px;
  }
  #subscribeMembers .dialog__title img {
    width: 30px;
    height: 30px;
    top: 6px;
  }
  #subscribeMembers .choose-right {
    width: auto;
  }
  #subscribeMembers .choose-total span {
    font-size: 18px;
  }
  #subscribeMembers .choose-container {
    width: calc(100% - 30px);
    height: 279px;
    overflow-y: hidden;
  }
  #subscribeMembers .choose-ti {
    font-size: 14px;
    line-height: 15px;
  }
  #subscribeMembers .choose-right {
    width: 100%;
  }
  #subscribeMembers .choose-right a {
    padding: 0px 10px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  #subscribeMembers .choose-list {
    margin-top: 15px;
    padding-bottom: 10px;
  }
  #subscribeMembers .choose-list li {
    margin-bottom: 5px;
  }
  #subscribeMembers .choose-total {
    margin-top: 20px;
  }
  #subscribeMembers .choose-add {
    margin-top: 20px;
  }
}
