#creators_index .maker-avatar {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
}
#creators_index .ant-pagination li:nth-last-child(2),
#creators_index .ant-pagination .ant-pagination-jump-next,
#creators_index .ant-pagination .ant-pagination-jump-prev {
  display: inline-table;
}
@media only screen and (max-width: 767px) {
  #creators_index .artistSection__img {
    width: 100%;
    float: none;
    padding-bottom: 100%;
    float: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
#creators_index .c02b478-music-table {
  text-align: center;
}
#creators_index .resultPagination a {
  color: rgba(0, 0, 0, 0.65);
}
#creators_index .ant-pagination-item-active a {
  color: #339e9d;
}
