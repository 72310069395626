#order_one .order_title_1 {
  border-width: 0px;
  margin-bottom: 5px;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 50px;
  background: inherit;
  background-color: #f1f6f6;
  border: none;
  border-radius: 8px;
  -moz-box-shadow: 1px 1px 5px rgba(31, 111, 100, 0.34901961);
  -webkit-box-shadow: 1px 1px 5px rgba(31, 111, 100, 0.34901961);
  box-shadow: 1px 1px 5px rgba(31, 111, 100, 0.34901961);
}
#order_one .order_left_and_right {
  margin: 0px 3%;
}
#order_one .order_title_inner {
  padding: 10px 20px;
}
#order_one .buypageForm__item {
  margin-bottom: 0px;
  padding: 10px 3.75%;
}
#order_one .border {
  border-radius: 8px;
}
#order_one .margin_top {
  margin-top: 30px;
}
#order_one .font_title_1 {
  font-family: 'Arial Negreta', 'Arial';
  font-weight: 700;
  font-size: 18px;
}
#order_one .font_title_2 {
  font-family: 'Arial Negreta', 'Arial';
  font-weight: 400;
  color: #666666;
  margin-left: 1.5em;
}
#order_one .margin_bottom_36 {
  margin-bottom: 20px;
}
#order_one .margin_left_15 {
  margin-left: 15px;
}
#order_one .margin_left_30 {
  margin-left: 30px;
}
#order_one .margin_top_18 {
  margin-top: 20px;
}
#order_one .buypageForm__dt_2 {
  width: 6.2em;
  margin-left: 30px;
}
#order_one .buypageForm__dd_2 {
  width: calc(47.5% - 9.2em);
}
#order_one .line_height_13 {
  line-height: 1.3;
}
#order_one .u-selectWrapp:after {
  border-width: 0;
  border-color: #349c9d;
  top: 0;
  margin-top: 0;
}
#order_one .ant-select-selection {
  border-radius: 8px;
}
#order_one .buypageForm__dd_phone {
  width: calc(52.25% - 8.2em);
}
#order_one .buypageForm__dt_email {
  width: 6.2em;
  margin-left: 30px;
}
#order_one .buypageForm__dd_email {
  width: calc(52.25% - 11.3em);
}
#order_one .cashiers__inner {
  font-size: 1.05em;
  text-align: center;
}
#order_one .pay_style {
  margin-top: 10px;
  margin-bottom: -12px;
}
#order_one .ant-radio-inner::after {
  height: 10px;
  width: 10px;
}
#order_one .notice_pay_red {
  color: #FF0000;
  font-size: 13px;
  margin-left: 66px;
}
#order_one .ant-radio-wrapper {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  #order_one .font_title_1 {
    font-size: 12px;
  }
  #order_one .ant-radio-wrapper {
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
  }
  #order_one .notice_pay_red {
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
  }
  #order_one .font_title_2 {
    font-size: 8px;
  }
  #order_one .order_title_1 {
    font-size: 12px;
    height: 60px;
  }
  #order_one .buypageForm__dt_2 {
    margin-left: 0px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    line-height: 1.3;
    padding-right: 1.2em;
    margin: 0.2em 0;
    box-sizing: border-box;
    margin-top: 20px;
  }
  #order_one .margin_left_15 {
    margin-left: 0px;
    margin-top: 20px;
  }
  #order_one .margin_left_30 {
    margin-left: 1px;
    margin-top: 20px;
    text-align: center;
  }
  #order_one .margin_top_18 {
    margin-top: 20px;
  }
  #order_one .buypageForm__dd_2 {
    margin-top: 20px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    width: calc(100% - 7em);
  }
  #order_one .buypageForm__dd_phone {
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    width: calc(100% - 7em);
  }
  #order_one .buypageForm__dt_email {
    margin-top: 20px;
    width: 6.2em;
    margin-left: 0px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
  }
  #order_one .buypageForm__dd_email {
    margin-top: 20px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    width: calc(100% - 7.2em);
  }
  #order_one .cashiers__inner {
    font-size: 12px;
  }
  #order_one .ant-select {
    font-size: 12px;
  }
  #order_one .u-inputSkin02 {
    font-size: 12px;
    height: 30px;
  }
}
#order_one .add_star:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
#order_one .my-items .ant-form-item-control {
  position: relative;
}
#order_one .my-items .ant-form-item-control .ant-form-explain {
  position: absolute;
  left: 0px;
  margin: 0px;
  font-size: 12px;
}
#order_one .dialog-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
  color: #000;
}
#order_one .choose-container {
  width: 660px;
  height: 640px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -360px;
  background: #fff;
  padding: 35px 20px 20px 25px;
}
#order_one .close-choose {
  overflow: scroll;
}
#order_one .close-choose a {
  float: right;
}
#order_one .choose-ti {
  font-size: 15px;
  line-height: 30px;
}
#order_one .choose-ti img {
  width: 30px;
  float: left;
  margin-right: 5px;
}
#order_one .choose-list {
  margin: 35px 0px 0px 0px;
  padding: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #d7d7d7;
}
#order_one .choose-list li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 15px;
}
#order_one .choose-left {
  float: left;
  width: 76px;
  font-size: 15px;
  line-height: 32px;
}
#order_one .choose-right {
  float: left;
  width: calc(100%-76px);
  width: -webkit-calc(24%);
  width: -moz-calc(24%);
}
#order_one .choose-right a {
  padding: 0px 15px;
  line-height: 30px;
  border: solid 1px #999999;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}
#order_one .choose-total {
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}
#order_one .choose-total span {
  color: #339e9d !important;
  font-weight: 600;
  font-size: 25px;
}
#order_one .choose-add {
  overflow: hidden;
  margin-top: 40px;
}
#order_one .choose-add a {
  width: 140px;
  line-height: 40px;
  display: inline-block;
  float: right;
  text-align: center;
  font-size: 14px;
  background: #339e9d;
  color: #fff;
  border-radius: 4px;
}
#order_one .scroll-y {
  overflow-y: hidden;
}
#order_one .booker-ti {
  font-size: 18px;
  text-align: center;
}
#order_one .book-code {
  font-size: 14px;
  padding: 25px 0px;
}
#order_one .book-de {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}
#order_one .book-de span {
  color: #ff0000;
  font-weight: 600;
}
#order_one .book-de a {
  color: #000;
}
#order_one .table-ti {
  font-size: 14px;
  margin-bottom: 20px;
}
#order_one .table-name {
  background: #f1f6f6;
  overflow: hidden;
}
#order_one .table-name div {
  float: left;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
#order_one .wid20 {
  width: 20%;
}
#order_one .wid60 {
  width: 60%;
}
#order_one .table-de ul {
  padding: 0px;
  margin: 0px;
}
#order_one .table-de ul li {
  list-style: none;
  border-bottom: solid 1px #cccccc;
  overflow: hidden;
  padding: 15px 0px;
}
#order_one .table-de ul li div {
  float: left;
  text-align: center;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}
#order_one .color-green {
  color: #339e9d;
}
#order_one .wid60 .table-all {
  font-size: 14px;
  line-height: 26px;
}
#order_one .line-78 {
  line-height: 78px;
}
#order_one .table-all span {
  width: 100px;
  display: inline-block;
  text-align: right;
}
#order_one .cancle {
  width: 160px;
  margin: 30px auto 0 auto;
  line-height: 40px;
  color: #fff;
  background: #339e9d;
  font-size: 14px;
  display: block;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
#order_one .book-input {
  margin-top: 40px;
}
#order_one .book-input ul {
  margin: 0px;
  padding: 0px;
}
#order_one .book-input ul li {
  list-style: none;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 20px;
}
#order_one .book-input ul li span {
  color: #ff0000;
}
#order_one .book-left {
  float: left;
  width: 170px;
  line-height: 34px;
}
#order_one .book-right {
  float: left;
}
#order_one .book-right input {
  width: 285px;
  height: 30px;
}
#order_one .my-sub {
  width: 230px;
  height: 40px;
  background: #339e9d;
  border-radius: 5px;
  margin: auto;
  display: inline-block;
  border: none;
  color: #fff;
}
#order_one .text-center {
  text-align: center;
}
@media (max-width: 768px) {
  #order_one .choose-container {
    width: calc(90%);
    height: 80%;
    margin-left: -45%;
  }
  #order_one .choose-ti {
    font-size: 14px;
    line-height: 15px;
  }
  #order_one .choose-right {
    width: 100%;
  }
  #order_one .choose-right a {
    padding: 0px 10px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  #order_one .choose-list {
    margin-top: 15px;
    padding-bottom: 10px;
  }
  #order_one .choose-list li {
    margin-bottom: 5px;
  }
  #order_one .choose-total {
    margin-top: 20px;
  }
  #order_one .choose-add {
    margin-top: 20px;
  }
  #order_one .table-all span {
    width: 60px;
    display: inline-block;
    text-align: right;
  }
  #order_one .scroll-y {
    overflow-y: scroll;
  }
  #order_one .book-code {
    font-size: 12px;
  }
}
