#creator_detail .artistSection__img {
  width: 20%;
  padding-bottom: 20%;
  float: left;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#creator_detail .audio_price {
  float: right;
  font-size: 18px;
  font-family: 'DIN Alternate', 'blod';
  color: #006666;
  font-weight: 600;
}
#creator_detail .musicItem__header__inner {
  float: left;
}
@media only screen and (max-width: 767px) {
  #creator_detail .audio_price {
    font-size: 14px;
  }
  #creator_detail .musicItem__utility__category {
    font-size: 11px;
  }
}
