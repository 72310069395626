#add_shop_car .dialog-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
}
#add_shop_car .choose-container {
  width: 730px;
  position: fixed;
  background: #fff;
  padding: 20px 20px 20px 40px;
}
#add_shop_car .close-choose {
  overflow: hidden;
  padding-bottom: 10px;
  margin-top: -10px;
}
#add_shop_car .close-choose a {
  float: right;
}
#add_shop_car .choose-ti {
  font-size: 15px;
  line-height: 30px;
}
#add_shop_car .choose-ti img {
  width: 30px;
  float: left;
  margin-right: 5px;
}
#add_shop_car .choose-list {
  margin: 35px 0px 0px 0px;
  padding: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #d7d7d7;
}
#add_shop_car .choose-list li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 15px;
}
#add_shop_car .choose-left {
  float: left;
  width: 76px;
  font-size: 15px;
  line-height: 32px;
  font-weight: 600;
}
#add_shop_car .choose-right {
  float: left;
  width: 593px;
}
#add_shop_car .choose-right a {
  padding: 0px 15px;
  line-height: 30px;
  border: solid 1px #999999;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}
#add_shop_car .choose-total {
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}
#add_shop_car .choose-total span {
  color: #339e9d !important;
  font-weight: 600;
  font-size: 25px;
}
#add_shop_car .choose-add {
  overflow: hidden;
  margin-top: 40px;
}
#add_shop_car .choose-add a {
  width: 140px;
  line-height: 40px;
  display: inline-block;
  float: right;
  text-align: center;
  font-size: 14px;
  background: #339e9d;
  color: #fff;
  border-radius: 4px;
}
#add_shop_car .dialog__title {
  font-size: 18px;
  text-align: center;
}
#add_shop_car .dialog__title img {
  width: 45px;
  height: 45px;
  margin-right: 20px;
  position: relative;
  top: 10px;
}
#add_shop_car .dialog__txt {
  text-align: center;
  margin-top: 70px;
}
#add_shop_car .dialog__txt a {
  width: 148px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  background: #fff;
  border: 1px solid #339e9d;
  color: #339e9d;
  border-radius: 4px;
}
#add_shop_car .add_car_success {
  height: 225px;
  padding-top: 35px;
}
#add_shop_car .margin_right_25 {
  margin-right: 70px;
}
#add_shop_car .dialog__txt a:hover {
  background: #339e9d !important;
  color: #fff !important;
}
#add_shop_car .notice_price {
  margin-top: 16px;
  color: #FF0000;
  margin-left: 50px;
  font-size: 13px;
}
@media only screen and (max-width: 767px) {
  #add_shop_car .choose-container {
    padding: 20px;
  }
  #add_shop_car .notice_price {
    font-size: 11px;
  }
  #add_shop_car .dialog__txt a {
    width: 103px;
    line-height: 30px;
    font-size: 12px;
  }
  #add_shop_car .margin_right_25 {
    margin-right: 35px;
  }
  #add_shop_car .dialog__title {
    font-size: 13px;
  }
  #add_shop_car .dialog__title img {
    width: 30px;
    height: 30px;
    top: 6px;
  }
  #add_shop_car .choose-right {
    width: auto;
  }
  #add_shop_car .choose-total span {
    font-size: 18px;
  }
  #add_shop_car .choose-container {
    width: calc(100% - 30px);
    height: 80%;
    overflow-y: scroll;
  }
  #add_shop_car .choose-ti {
    font-size: 14px;
    line-height: 15px;
  }
  #add_shop_car .choose-right {
    width: 100%;
  }
  #add_shop_car .choose-right a {
    padding: 0px 10px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  #add_shop_car .choose-list {
    margin-top: 15px;
    padding-bottom: 10px;
  }
  #add_shop_car .choose-list li {
    margin-bottom: 5px;
  }
  #add_shop_car .choose-total {
    margin-top: 20px;
  }
  #add_shop_car .choose-add {
    margin-top: 20px;
  }
}
