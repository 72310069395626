#receipt .modalForm__header {
  background-color: #fff;
  position: relative;
  font-size: 1.5rem;
  padding: 0.8em;
}
#receipt .buypageForm {
  padding: 1.6875% 3.125% 4.6875%;
  background-color: #f1f6f6;
}
#receipt .modalBox__inner {
  width: 50%;
}
#receipt .buypageForm__inner {
  max-width: 80%;
  margin: 0 auto;
}
#receipt .buypageForm__dd {
  width: calc(100% - 8.2em);
}
#receipt .add_star:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
#receipt .buypageForm__dt {
  font-size: 1em;
  width: 8.2em;
  line-height: 1.3;
  padding-right: 1.2em;
  margin: 0.2em 0;
  box-sizing: border-box;
}
#receipt .buypageForm__item {
  font-size: 0.95em;
  padding: 1% 3.75%;
}
#receipt .u-inputSkin02 {
  font-size: 0.95em;
}
@media only screen and (max-width: 767px) {
  #receipt .modalBox__inner {
    width: 94%;
  }
  #receipt .buypageForm__dd {
    font-size: 12px;
    width: 100%;
  }
  #receipt .modalForm__title {
    font-size: 18px;
  }
}
