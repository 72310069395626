.modal {
  position: fixed;
  height: 520px;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #000;
  text-align: center;
  border-radius: 8px;
  border: 20px;
  background: #044141;
  overflow: hidden;
  z-index: 9999;
  box-shadow: inset 0 0 10px 0 #fff;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5px;
  background: #cecdcd;
  opacity: 0.1;
  z-index: 9998;
}
.aboutBox__inner {
  padding: 0px 25px;
  text-align: left;
}
