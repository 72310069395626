.c02b478-music-table .page-ov {
  margin-top: 60px;
  text-align: center;
}
.c02b478-music-table .social-share .icon-wechat .wechat-qrcode {
  top: -198px;
  left: -64px;
  width: 150px;
  height: 188px;
}
.c02b478-music-table .audio_price {
  float: right;
  font-size: 18px;
  font-family: 'DIN Alternate', 'blod';
  color: #006666;
  font-weight: 600;
}
.c02b478-music-table .musicItem__header__inner {
  float: left;
}
.c02b478-music-table .v_style {
  width: 40px;
  position: absolute;
  left: 0px;
}
.c02b478-music-table .v_img_style {
  margin-top: -20px;
}
.c02b478-music-table .v_position {
  position: relative;
}
.c02b478-music-table .phone_v_style {
  display: none;
}
.c02b478-music-table .ant-pagination-options-quick-jumper input {
  height: 20px;
}
.c02b478-music-table .totlPageClass {
  display: inline;
  width: 8rem;
  margin-left: 0px;
  font-family: Arial;
  color: rgba(0, 0, 0, 0.65);
  float: left;
  height: 32px;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .c02b478-music-table .audio_price {
    font-size: 14px;
  }
  .c02b478-music-table .musicItem__utility__category {
    font-size: 11px;
  }
  .c02b478-music-table .favItem .musicItem__header {
    padding-right: 0%;
    width: auto;
  }
  .c02b478-music-table .v_img_style {
    display: none;
  }
  .c02b478-music-table .phone_v_style {
    width: 18px;
    display: initial;
  }
  .c02b478-music-table .ant-pagination-options-quick-jumper input {
    height: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .c02b478-music-table .ant-pagination-options {
    display: inline-block;
  }
}
