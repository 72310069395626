#c02b478-footer .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
#c02b478-footer .right {
  float: right;
}
#c02b478-footer .right:not(:first-child) {
  margin-right: 30px;
}
.globalFooter__nav__item:last-child {
  width: 12.5em;
}
.info {
  font-size: 12px;
  clear: both;
  height: 20px;
  line-height: 20px;
  padding: 7px 0 70px 0;
  text-align: center;
  border-top: 1px solid #fff;
}
.my_fixedMenu {
  display: table;
  width: 90px;
  margin-bottom: 18px;
  position: absolute;
  right: 0px !important;
  cursor: pointer;
}
.my_fixedMenu img {
  width: 100%;
}
.fixedMenu__item-01 {
  margin-top: 145px;
}
.a_youqing:hover {
  text-decoration: none !important;
  color: #339e9d !important;
  font-size: 13px;
}
.a_youqing {
  text-decoration: none !important;
  color: #fff;
  font-size: 13px;
}
.s_youqing {
  font-size: 13px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  height: 100%;
  float: left;
  margin-top: -24px;
}
