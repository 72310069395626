#search_less .ant-pagination li:nth-last-child(2) {
  display: none;
}
#search_less .callBackTort {
  font-size: 0.88rem;
  color: #339e9d;
  text-align: right;
  margin-right: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  display: block;
  width: 60px;
  line-height: 36px;
}
#search_less .sty {
  height: 42px;
  float: right;
  width: 20%;
  text-align: -webkit-right;
}
#search_less .my-tabs {
  float: left;
  width: 80%;
}
#search_less .subscribe {
  height: 42px;
  line-height: 42px;
  float: left;
}
#search_less .u-checkbox input + span:before,
#search_less .u-checkbox2 input + span:before {
  border: solid 2px #cf9823;
}
#search_less .freeDiv input + span:before {
  border: solid 2px #9A0000;
}
#search_less .materialDiv input:checked + span:after {
  border-right: solid 3px #336633;
  border-bottom: solid 3px #336633;
}
#search_less .materialDiv input + span:before {
  border: solid 2px #336633;
}
#search_less .materialDiv {
  margin-left: 10px;
}
#call_back_cort .modalBox__inner {
  overflow-y: auto;
  position: fixed;
  z-index: 50;
  top: 50%;
  left: 0;
  right: 0;
  box-sizing: border-box;
  width: 515px;
  max-width: 94%;
  max-width: calc(100% - 80px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  max-height: 68%;
}
#call_back_cort .modalForm {
  background-color: #f1f6f6;
  background-color: #fff;
  box-shadow: 0px 2px 2px 3px rgba(0, 0, 0, 0.15);
  text-align: center;
}
#call_back_cort .modalForm__header {
  background-color: #fff;
  position: relative;
  font-size: 1.5rem;
  padding: 0.25em;
}
#call_back_cort .modalForm__inner {
  text-align: left;
}
#call_back_cort .buypageBox {
  background-color: #f1f6f6;
  color: #333;
}
#call_back_cort .buypageForm {
  padding: 1.6875% 3.125% 4.6875%;
  background-color: #f1f6f6;
}
#call_back_cort form {
  display: block;
  margin-top: 0em;
}
#call_back_cort .buypageForm__inner {
  max-width: 400px;
  margin: 0 auto;
}
#call_back_cort .buypageForm__items {
  margin-bottom: 60px;
}
#call_back_cort .buypageForm__item {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 0.5% 2.75%;
  position: relative;
}
#call_back_cort .u-submitSkin01 {
  display: block;
  -webkit-appearance: none;
  color: #fff;
  width: 50%;
  display: table-cell;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: solid 2px #339e9d;
  padding: 4px 30px;
  line-height: 1.4;
  height: 3.3em;
  font-size: 1rem;
  box-sizing: border-box;
  border-radius: 0;
  cursor: pointer;
  background-color: #339e9d;
  transition-duration: 0.3s;
  transition-property: background-color border;
  margin: 5px auto;
}
#call_back_cort .u-inputSkin02 {
  width: 100%;
  padding: 2px 20px;
  background: #fff;
  border: none;
  border: solid 1px #d9d9d9;
  box-sizing: border-box;
  padding: 0.2em 0.5em;
  font-size: 1rem;
}
#call_back_cort .u-inputSkin021 {
  width: 100%;
  padding: 2px 20px;
  background: #fff;
  border: none;
  border: solid 1px #d9d9d9;
  box-sizing: border-box;
  padding: 0.2em 0.5em;
  font-size: 1rem;
  height: 100px;
}
.tabs-li {
  overflow: hidden;
}
.tabs-li li {
  float: left;
  margin-right: 7px;
}
.tabs-li li a {
  font-size: 12px;
  color: #333333;
  height: 19px;
  padding: 3px;
  border-radius: 5px;
  border: solid #58c9c9 1px;
  display: inline-block;
  background: #fff;
  line-height: 20px;
  cursor: pointer;
  margin-top: 5px;
}
.cancle-img {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 2px;
  cursor: pointer;
}
.cancle-img img {
  display: block;
  width: 9px;
  height: 9px;
  position: absolute;
  margin-top: 1px;
}
.tabs-li .clear-tag {
  font-size: 12px;
  height: 25px;
  padding: 0px;
  border: solid #f1f6f6 1px;
  display: inline-block;
  background: #f1f6f6;
  line-height: 26px;
  cursor: pointer;
  color: #797979;
}
#add_shop_car .buypageBox {
  background-color: #fff;
}
#add_shop_car .buypageForm {
  background-color: #fff;
  padding: 0px;
}
#add_shop_car .modalBox__inner {
  width: 721px;
}
#add_shop_car .modalForm__close button {
  width: 26px;
  height: 26px;
}
#add_shop_car .modalForm__close {
  right: 17px;
}
#add_shop_car .modalForm__inner {
  padding: 0px 40px;
}
#add_shop_car .font_size_15 {
  font-size: 15px;
  margin-bottom: 40px;
}
#audio_one_css .audio_price {
  float: right;
  font-size: 18px;
  font-family: 'DIN Alternate', 'blod';
  color: #006666;
  font-weight: 600;
}
#audio_one_css .musicItem__header__inner {
  float: left;
}
@media only screen and (max-width: 767px) {
  #audio_one_css .audio_price {
    font-size: 14px;
  }
}
