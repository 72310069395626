.form__balloon.hidden {
  display: none;
}
.showErr {
  width: 550px;
  background-color: #339e9d;
  height: 50px;
  text-align: center;
  margin: 40px auto 0;
  border-radius: 8px;
  color: #fff;
}
.buypageForm__dd img {
  width: 160px;
  height: 160px;
}
