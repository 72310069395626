#user_subscribe_order {
  /* 新添订单样式 */
}
#user_subscribe_order .pag_center {
  text-align: center;
  margin-top: 10px;
}
#user_subscribe_order .buyRecordBox__size_order_id {
  font-family: 'Arial Negreta', 'Arial';
  font-weight: 700;
  font-size: 16px;
}
#user_subscribe_order .buyRecordBox__size_title {
  font-family: 'Arial Negreta', 'Arial';
  font-size: 15px;
  font-weight: 600;
  color: #666666;
}
#user_subscribe_order .buyRecordBox__header {
  padding: 20px 5% 10px;
}
#user_subscribe_order .subscribe_date {
  margin-bottom: 15px;
}
#user_subscribe_order .h-w-0 {
  height: 0px;
  width: 0px;
  overflow: hidden;
}
#user_subscribe_order .buyRecordBox__size {
  width: 25%;
  float: left;
  font-size: 15px;
  text-align: center;
}
#user_subscribe_order .buyRecord__table th {
  padding-left: 0px;
  font-weight: normal;
}
#user_subscribe_order .buyRecord__table td {
  padding-right: 0px;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
#user_subscribe_order .buyRecord__table__price {
  font-size: 15px;
}
#user_subscribe_order .song_title_size {
  font-size: 15px;
  width: 50%;
}
#user_subscribe_order .shengluehao {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#user_subscribe_order .border_bottom {
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}
#user_subscribe_order .buyRecordId__size {
  width: 33%;
  float: left;
  font-size: 15px;
  text-align: center;
  padding-bottom: 10px;
  margin-top: 2px;
}
#user_subscribe_order .buyRecordBox__size_tent {
  font-size: 15px;
  margin-left: 5px;
}
#user_subscribe_order .buyRecord__table table {
  width: 100%;
  font-size: 15px;
}
#user_subscribe_order .buyRecord__table {
  width: 100%;
  float: left;
  font-size: 15px;
}
#user_subscribe_order .buyRecordBox.is-open .buyRecordBox__main {
  display: -webkit-flex;
  min-height: 40px;
}
#user_subscribe_order .buyRecordBox__main {
  overflow: hidden;
  align-items: center;
  /*//子元素垂直居中*/
  display: -webkit-flex;
}
#user_subscribe_order .shenqing {
  text-align: center;
  width: 20%;
  height: 100%;
  float: right;
  margin-top: -12px;
}
#user_subscribe_order .pay_money {
  font-size: 15px;
  cursor: pointer;
}
#user_subscribe_order .pay_button {
  line-height: 1px;
  padding: 0.38em 0.38em;
  cursor: pointer;
  background: #339e9d;
  color: #fff;
  border: solid 0px #339e9d;
  outline: none;
  width: 105px;
  height: 36px;
  border-radius: 5px;
  font-size: 15px;
}
#user_subscribe_order .buyRecord__table__book a {
  color: #339e9d;
  font-size: 15px;
}
#user_subscribe_order .text_align {
  text-align: right;
}
#user_subscribe_order .pay_button {
  font-size: 15px;
  height: 29px;
}
#user_subscribe_order .phone-show {
  display: none;
}
#user_subscribe_order .notice_price {
  color: #FF0000;
  font-size: 13px;
  display: block;
  margin-top: -25px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  #user_subscribe_order .m-tabBox__list__item a {
    font-size: 12px;
  }
  #user_subscribe_order .notice_price {
    font-size: 11px;
  }
  #user_subscribe_order .buyRecordBox__size {
    width: 100%;
    text-align: left;
    line-height: 24px;
  }
  #user_subscribe_order .buyRecordBox__size_title {
    font-size: 12px;
  }
  #user_subscribe_order .buyRecordBox__size_tent {
    font-size: 12px;
  }
  #user_subscribe_order .phone-hide {
    display: none;
  }
  #user_subscribe_order .phone-show {
    display: block;
  }
  #user_subscribe_order .float-right {
    float: right;
  }
  #user_subscribe_order .cancle-book {
    font-size: 12px;
    text-decoration: underline;
    color: #666666;
  }
  #user_subscribe_order .buyRecordBox.is-open .buyRecordBox__main {
    display: block;
  }
  #user_subscribe_order .buyRecord__table {
    width: 100%;
    display: block;
    float: none;
  }
  #user_subscribe_order .shenqing {
    width: 100%;
    float: none;
  }
  #user_subscribe_order .buyRecord__table__price {
    text-align: right !important;
    font-size: 12px;
  }
  #user_subscribe_order .shengluehao {
    font-size: 12px;
  }
  #user_subscribe_order .phone-price {
    padding-right: 1em;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 10px;
  }
  #user_subscribe_order .pay_button {
    width: 100px;
    height: 25px;
    font-size: 12px;
  }
  #user_subscribe_order .pay_money {
    font-size: 12px;
  }
  #user_subscribe_order .song_title_size {
    padding-left: 1em !important;
    width: 40%;
  }
  #user_subscribe_order .buyRecord__table__book a {
    font-size: 12px;
  }
  #user_subscribe_order .buyRecord__table td:last-child {
    width: 12em;
  }
  #user_subscribe_order .shengluehao {
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #user_subscribe_order .dlLink:after {
    background: none;
  }
}
#user_subscribe_order .dialog-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
  color: #000;
}
#user_subscribe_order .choose-container {
  width: 660px;
  height: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -360px;
  background: #fff;
  padding: 20px 40px 20px 40px;
}
#user_subscribe_order .code_style {
  height: auto;
  width: 555px;
  left: 55%;
}
#user_subscribe_order .close-choose {
  overflow: hidden;
}
#user_subscribe_order .close-choose a {
  float: right;
}
#user_subscribe_order .choose-ti {
  font-size: 15px;
  line-height: 30px;
}
#user_subscribe_order .choose-ti img {
  width: 30px;
  float: left;
  margin-right: 5px;
}
#user_subscribe_order .choose-list {
  margin: 35px 0px 0px 0px;
  padding: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #d7d7d7;
}
#user_subscribe_order .choose-list li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 15px;
}
#user_subscribe_order .choose-left {
  float: left;
  width: 76px;
  font-size: 15px;
  line-height: 32px;
}
#user_subscribe_order .choose-right {
  float: left;
  width: calc(100%-76px);
  width: -webkit-calc(24%);
  width: -moz-calc(24%);
}
#user_subscribe_order .choose-right a {
  padding: 0px 15px;
  line-height: 30px;
  border: solid 1px #999999;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}
#user_subscribe_order .choose-total {
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}
#user_subscribe_order .choose-total span {
  color: #339e9d !important;
  font-weight: 600;
  font-size: 25px;
}
#user_subscribe_order .choose-add {
  overflow: hidden;
  margin-top: 40px;
}
#user_subscribe_order .choose-add a {
  width: 140px;
  line-height: 40px;
  display: inline-block;
  float: right;
  text-align: center;
  font-size: 14px;
  background: #339e9d;
  color: #fff;
  border-radius: 4px;
}
#user_subscribe_order .scroll-y {
  overflow-y: hidden;
}
#user_subscribe_order .booker-ti {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}
#user_subscribe_order .book-code {
  font-size: 14px;
  padding: 25px 0px;
}
#user_subscribe_order .book-de {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}
#user_subscribe_order .book-de span {
  color: #ff0000;
  font-weight: 600;
}
#user_subscribe_order .book-de a {
  color: #000;
}
#user_subscribe_order .table-ti {
  font-size: 14px;
  margin-bottom: 20px;
}
#user_subscribe_order .table-name {
  background: #f1f6f6;
  overflow: hidden;
}
#user_subscribe_order .table-name div {
  float: left;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
#user_subscribe_order .wid20 {
  width: 20%;
}
#user_subscribe_order .wid60 {
  width: 60%;
}
#user_subscribe_order .table-de ul {
  padding: 0px;
  margin: 0px;
}
#user_subscribe_order .table-de ul li {
  list-style: none;
  border-bottom: solid 1px #cccccc;
  overflow: hidden;
  padding: 15px 0px;
}
#user_subscribe_order .table-de ul li div {
  float: left;
  text-align: center;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}
#user_subscribe_order .color-green {
  color: #339e9d;
}
#user_subscribe_order .wid60 .table-all {
  font-size: 14px;
  line-height: 26px;
}
#user_subscribe_order .line-78 {
  line-height: 78px;
}
#user_subscribe_order .table-all span {
  width: 100px;
  display: inline-block;
  text-align: right;
}
#user_subscribe_order .cancle {
  width: 160px;
  margin: 30px auto 0 auto;
  line-height: 40px;
  color: #fff;
  background: #339e9d;
  font-size: 14px;
  display: block;
  text-align: center;
  border-radius: 5px;
}
#user_subscribe_order .book-input {
  margin-top: 40px;
}
#user_subscribe_order .book-input ul {
  margin: 0px;
  padding: 0px;
}
#user_subscribe_order .book-input ul li {
  list-style: none;
  font-size: 14px;
  overflow: hidden;
}
#user_subscribe_order .margin_bottom_20 {
  margin-bottom: 20px;
}
#user_subscribe_order .book-input ul li span {
  color: #ff0000;
}
#user_subscribe_order .book-left {
  float: left;
  width: 170px;
  line-height: 34px;
}
#user_subscribe_order .book-right {
  float: left;
}
#user_subscribe_order .book-right input {
  width: 285px;
  height: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
#user_subscribe_order .my-sub {
  width: 230px;
  height: 40px;
  background: #339e9d;
  border-radius: 5px;
  margin: auto;
  display: inline-block;
  border: none;
  color: #fff;
  cursor: pointer;
}
#user_subscribe_order .text-margin-top {
  margin-bottom: 20px;
  margin-top: 20px;
}
#user_subscribe_order .text-center {
  text-align: center;
}
#user_subscribe_order .ant-select-arrow .ant-select-arrow-icon {
  color: #339e9d;
}
#user_subscribe_order .select_width {
  width: 287px;
}
@media (max-width: 768px) {
  #user_subscribe_order .choose-container {
    width: calc(90%);
    height: 80%;
    margin-left: -45%;
  }
  #user_subscribe_order .code_style {
    height: auto;
    width: 555px;
    margin-left: -52%;
  }
  #user_subscribe_order .choose-ti {
    font-size: 14px;
    line-height: 15px;
  }
  #user_subscribe_order .choose-right {
    width: 100%;
  }
  #user_subscribe_order .choose-right a {
    padding: 0px 10px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  #user_subscribe_order .choose-list {
    margin-top: 15px;
    padding-bottom: 10px;
  }
  #user_subscribe_order .choose-list li {
    margin-bottom: 5px;
  }
  #user_subscribe_order .choose-total {
    margin-top: 20px;
  }
  #user_subscribe_order .choose-add {
    margin-top: 20px;
  }
  #user_subscribe_order .book-right input {
    width: 100%;
    height: 30px;
  }
  #user_subscribe_order .select_width {
    width: 100%;
  }
  #user_subscribe_order .table-all span {
    width: 60px;
    display: inline-block;
    text-align: right;
  }
  #user_subscribe_order .scroll-y {
    overflow-y: scroll;
  }
  #user_subscribe_order .book-right {
    float: left;
    width: 93%;
  }
  #user_subscribe_order .margin_bottom_20 {
    margin-bottom: 0px;
  }
  #user_subscribe_order .phone_margin_top {
    margin-top: 10px;
  }
}
#user_subscribe_order .border-li li {
  padding: 0px 40px;
  background: #fff;
  margin-bottom: 10px;
}
#user_subscribe_order .order-time {
  font-size: 15px;
  line-height: 60px;
}
#user_subscribe_order .order-time span {
  font-size: 16px;
  font-weight: 600;
}
#user_subscribe_order .order-ti {
  border-bottom: solid 1px #cccccc;
  line-height: 20px;
  font-size: 15px;
  font-weight: 600;
  overflow: hidden;
}
#user_subscribe_order .order-end {
  float: right;
}
#user_subscribe_order .order-ti001 {
  width: calc(45% - 20px);
  float: left;
  padding-left: 20px;
}
#user_subscribe_order .order-ti002 {
  width: calc(35% - 20px);
  float: left;
  padding-left: 20px;
}
#user_subscribe_order .order-ti003 {
  width: 20%;
  float: left;
  text-align: center;
}
#user_subscribe_order .order-de {
  box-sizing: border-box;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 15px 0px;
}
#user_subscribe_order .order-de .order-ti001 {
  font-size: 15px;
}
#user_subscribe_order .order-de .order-ti002 {
  font-size: 15px;
}
#user_subscribe_order .go-pay {
  font-size: 13px;
  color: #fff;
  line-height: 29px;
  background: #339e9d;
  border-radius: 4px;
  padding: 0px 22px;
  display: inline-block;
}
#user_subscribe_order .go-cancle {
  font-size: 13px;
  text-decoration: underline;
  line-height: 20px;
  display: inline-block;
  margin-top: 15px;
}
@media (max-width: 768px) {
  #user_subscribe_order .border-li li {
    padding: 0px 20px;
  }
  #user_subscribe_order .order-ti div {
    display: none;
  }
  #user_subscribe_order .order-ti003 {
    font-size: 12px;
    width: 40%;
  }
  #user_subscribe_order .order-time {
    font-size: 12px;
    line-height: 22px;
    padding: 15px 0px 10px 0px;
  }
  #user_subscribe_order .order-end {
    float: none;
  }
  #user_subscribe_order .order-time span {
    font-size: 12px;
  }
  #user_subscribe_order .order-de .order-ti001 {
    font-size: 12px;
    width: 30%;
    padding: 0px;
  }
  #user_subscribe_order .order-de .order-ti002 {
    font-size: 12px;
    width: 30%;
    padding: 0px;
  }
  #user_subscribe_order .go-pay {
    padding: 0px;
  }
}
.ant-modal-wrap .ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #339e9d;
}
.ant-modal-wrap .ant-btn-danger {
  color: #fff;
  background-color: #339e9d;
  border-color: #339e9d;
}
.ant-modal-wrap .ant-modal {
  top: 200px;
}
.ant-modal-wrap .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #339e9d;
}
.ant-modal-wrap .ant-modal-confirm-body .ant-modal-confirm-title {
  padding-top: 3px;
}
.ant-modal-wrap .anticon svg {
  margin-left: 2.8em;
}
@media only screen and (max-width: 767px) {
  .ant-modal-wrap .anticon svg {
    margin-left: 1.5em;
  }
}
