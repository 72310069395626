#topic_music .ant-pagination li:nth-last-child(2),
#topic_music .ant-pagination .ant-pagination-jump-next,
#topic_music .ant-pagination .ant-pagination-jump-prev {
  display: inline-table;
}
#topic_music .creatorpage-ov {
  text-align: center;
}
#topic_music .audio_price {
  float: right;
  font-size: 18px;
  font-family: 'DIN Alternate', 'blod';
  color: #006666;
  font-weight: 600;
}
#topic_music .musicItem__header__inner {
  float: left;
}
#topic_music .phone_v_style {
  display: none;
}
@media only screen and (max-width: 767px) {
  #topic_music .v_img_style {
    display: none;
  }
  #topic_music .phone_v_style {
    width: 18px;
    display: initial;
  }
}
