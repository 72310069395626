.m-btn-type2-select {
  font-size: 0.88rem;
  display: block;
  text-align: center;
  padding: 0.8em 0.5em;
  position: relative;
  box-sizing: border-box;
}
.m-btn-type2-select:active,
.m-btn-type2-select:hover,
.m-btn-type2-select:link,
.m-btn-type2-select:visited {
  text-decoration: none;
  color: #fff;
}
.m-btn-type2-select:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1px #48abb1;
  box-sizing: border-box;
  border-image: linear-gradient(to right, #148a80 0%, #8ad6ef 100%) 1 stretch;
  transition-property: opacity, border, height, border-image, -webkit-transform;
  transition-property: opacity, transform, border, height, border-image;
  transition-property: opacity, transform, border, height, border-image, -webkit-transform;
  transition-duration: 0.3s;
  border: solid 4px #fff;
  border-image: transparent;
}
