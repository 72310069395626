.c132-cart {
  /*    会员购买    */
}
.c132-cart .buypageForm {
  padding: 0%;
  background-color: #fff;
  padding-top: 3%;
}
.c132-cart .buypageForm__inner {
  max-width: 100%;
  margin: 0 auto;
}
.c132-cart .cashiers__table {
  margin-bottom: 0px;
}
.c132-cart .subscribe_use {
  height: 60px;
  width: 97%;
  margin: 0 auto 30px;
  background-color: #f2f2f2;
  padding-left: 20px;
}
.c132-cart .u-checkbox2 input + span:before {
  border-color: #339e9d;
  border-image: none;
}
.c132-cart .subscribe_use_p {
  font-size: 15px;
  margin-top: 16px;
  display: inline-block;
}
.c132-cart .subscribe_use_num {
  font-size: 13px;
}
.c132-cart .buyMusicItem__scope {
  font-size: 0.88rem;
  text-align: left;
  justify-self: flex-end;
  margin-left: 10px;
}
.c132-cart .buyMusicItem__header {
  width: 33%;
}
.c132-cart .margin_left_and_right {
  margin: 0px 3%;
}
.c132-cart .cashiers__table__notice {
  text-align: right;
  color: #FF0000;
  font-size: 13px;
}
.c132-cart .my-pc-hide {
  display: none;
}
.c132-cart .mr-pc-show {
  display: block;
}
.c132-cart .wechat_code_phone {
  display: block;
}
.c132-cart .wechat_code_phone2 {
  display: none;
}
.c132-cart .code_style {
  height: auto;
  width: 555px;
  left: 55%;
}
@media only screen and (max-width: 767px) {
  .c132-cart .buyMusicItem__check {
    top: 25%;
  }
  .c132-cart .cashiers__table__notice {
    font-size: 11px;
  }
  .c132-cart .buyMusicItem__scope {
    font-size: calc(0.625rem + 2 * (100vw - 320px) / 448);
  }
  .c132-cart .my-pc-hide {
    display: block;
    margin-top: 10px;
    padding-bottom: 5px;
  }
  .c132-cart .mr-pc-show {
    display: none;
  }
  .c132-cart .buyMusicItem__header {
    width: 73% !important;
  }
  .c132-cart .my-pc-hide p {
    margin: 0px;
  }
}
.c132-cart .my-items .ant-form-item-control {
  position: relative;
}
.c132-cart .my-items .ant-form-item-control .ant-form-explain {
  position: absolute;
  left: 0px;
  margin: 0px;
  font-size: 12px;
}
.c132-cart .dialog-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
  color: #000;
}
.c132-cart .choose-container {
  width: 660px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -360px;
  background: #fff;
  padding: 35px 20px 50px 25px;
}
.c132-cart .close-choose {
  overflow: scroll;
}
.c132-cart .close-choose a {
  float: right;
}
.c132-cart .choose-ti {
  font-size: 15px;
  line-height: 30px;
}
.c132-cart .choose-ti img {
  width: 30px;
  float: left;
  margin-right: 5px;
}
.c132-cart .choose-list {
  margin: 35px 0px 0px 0px;
  padding: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #d7d7d7;
}
.c132-cart .choose-list li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 15px;
}
.c132-cart .choose-left {
  float: left;
  width: 76px;
  font-size: 15px;
  line-height: 32px;
}
.c132-cart .choose-right {
  float: left;
  width: calc(100%-76px);
  width: -webkit-calc(24%);
  width: -moz-calc(24%);
}
.c132-cart .choose-right a {
  padding: 0px 15px;
  line-height: 30px;
  border: solid 1px #999999;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}
.c132-cart .choose-total {
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}
.c132-cart .choose-total span {
  color: #339e9d !important;
  font-weight: 600;
  font-size: 25px;
}
.c132-cart .choose-add {
  overflow: hidden;
  margin-top: 40px;
}
.c132-cart .choose-add a {
  width: 140px;
  line-height: 40px;
  display: inline-block;
  float: right;
  text-align: center;
  font-size: 14px;
  background: #339e9d;
  color: #fff;
  border-radius: 4px;
}
.c132-cart .scroll-y {
  overflow-y: hidden;
}
.c132-cart .booker-ti {
  font-size: 18px;
  text-align: center;
}
.c132-cart .book-code {
  font-size: 14px;
  padding: 25px 0px;
}
.c132-cart .book-de {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}
.c132-cart .book-de span {
  color: #ff0000;
  font-weight: 600;
}
.c132-cart .book-de a {
  color: #000;
}
.c132-cart .table-ti {
  font-size: 14px;
  margin-bottom: 20px;
}
.c132-cart .table-name {
  background: #f1f6f6;
  overflow: hidden;
}
.c132-cart .table-name div {
  float: left;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
.c132-cart .wid20 {
  width: 20%;
}
.c132-cart .wid60 {
  width: 60%;
}
.c132-cart .table-de ul {
  padding: 0px;
  margin: 0px;
}
.c132-cart .table-de ul li {
  list-style: none;
  border-bottom: solid 1px #cccccc;
  overflow: hidden;
  padding: 15px 0px;
}
.c132-cart .table-de ul li div {
  float: left;
  text-align: center;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}
.c132-cart .color-green {
  color: #339e9d;
}
.c132-cart .wid60 .table-all {
  font-size: 14px;
  line-height: 26px;
}
.c132-cart .line-78 {
  line-height: 78px;
}
.c132-cart .table-all span {
  width: 100px;
  display: inline-block;
  text-align: right;
}
.c132-cart .cancle {
  width: 160px;
  margin: 30px auto 0 auto;
  line-height: 40px;
  color: #fff;
  background: #339e9d;
  font-size: 14px;
  display: block;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.c132-cart .book-input {
  margin-top: 40px;
}
.c132-cart .book-input ul {
  margin: 0px;
  padding: 0px;
}
.c132-cart .book-input ul li {
  list-style: none;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 20px;
}
.c132-cart .book-input ul li span {
  color: #ff0000;
}
.c132-cart .book-left {
  float: left;
  width: 170px;
  line-height: 34px;
}
.c132-cart .book-right {
  float: left;
}
.c132-cart .book-right input {
  width: 285px;
  height: 30px;
}
.c132-cart .my-sub {
  width: 230px;
  height: 40px;
  background: #339e9d;
  border-radius: 5px;
  margin: auto;
  display: inline-block;
  border: none;
  color: #fff;
}
.c132-cart .text-center {
  text-align: center;
}
@media (max-width: 768px) {
  .c132-cart .choose-container {
    width: calc(90%);
    height: 80%;
    margin-left: -45%;
  }
  .c132-cart .choose-ti {
    font-size: 14px;
    line-height: 15px;
  }
  .c132-cart .choose-right {
    width: 100%;
  }
  .c132-cart .choose-right a {
    padding: 0px 10px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .c132-cart .choose-list {
    margin-top: 15px;
    padding-bottom: 10px;
  }
  .c132-cart .choose-list li {
    margin-bottom: 5px;
  }
  .c132-cart .choose-total {
    margin-top: 20px;
  }
  .c132-cart .choose-add {
    margin-top: 20px;
  }
  .c132-cart .table-all span {
    width: 60px;
    display: inline-block;
    text-align: right;
  }
  .c132-cart .scroll-y {
    overflow-y: scroll;
  }
  .c132-cart .book-code {
    font-size: 12px;
  }
  .c132-cart .img_phone_hidden {
    display: none;
  }
}
.c132-cart #order_one .order_title_1 {
  border-width: 0px;
  margin-bottom: 5px;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 50px;
  background: inherit;
  background-color: #f1f6f6;
  border: none;
  border-radius: 2px;
  -moz-box-shadow: 1px 1px 5px rgba(31, 111, 100, 0.34901961);
  -webkit-box-shadow: 1px 1px 5px rgba(31, 111, 100, 0.34901961);
  box-shadow: 1px 1px 5px rgba(31, 111, 100, 0.34901961);
}
.c132-cart #order_one .order_left_and_right {
  margin: 0px 3%;
}
.c132-cart #order_one .order_title_inner {
  padding: 10px 20px;
}
.c132-cart #order_one .buypageForm__item {
  margin-bottom: 0px;
  padding: 10px 3.75%;
}
.c132-cart #order_one .border {
  border-radius: 8px;
}
.c132-cart #order_one .margin_top {
  margin-top: 30px;
}
.c132-cart #order_one .font_title_1 {
  font-family: 'Arial Negreta', 'Arial';
  font-weight: 700;
  font-size: 18px;
}
.c132-cart #order_one .font_title_2 {
  font-family: 'Arial Negreta', 'Arial';
  font-weight: 400;
  color: #666666;
  margin-left: 1.5em;
}
.c132-cart #order_one .margin_bottom_36 {
  margin-bottom: 20px;
}
.c132-cart #order_one .margin_left_15 {
  margin-left: 15px;
}
.c132-cart #order_one .margin_left_30 {
  margin-left: 30px;
}
.c132-cart #order_one .margin_top_18 {
  margin-top: 30px;
}
.c132-cart #order_one .buypageForm__dt_2 {
  width: 6.2em;
  margin-left: 30px;
}
.c132-cart #order_one .buypageForm__dd_2 {
  width: calc(47.5% - 9.2em);
}
.c132-cart #order_one .line_height_13 {
  line-height: 1.3;
}
.c132-cart #order_one .u-selectWrapp:after {
  border-width: 0;
  border-color: #349c9d;
  top: 0;
  margin-top: 0;
}
.c132-cart #order_one .ant-select-selection {
  border-radius: 8px;
}
.c132-cart #order_one .buypageForm__dd_phone {
  width: calc(52.25% - 8.2em);
}
.c132-cart #order_one .buypageForm__dt_email {
  width: 6.2em;
  margin-left: 30px;
}
.c132-cart #order_one .buypageForm__dd_email {
  width: calc(52.25% - 10.1em);
}
.c132-cart #order_one .cashiers__inner {
  font-size: 1.05em;
  text-align: center;
}
.c132-cart #order_one .pay_style {
  margin-top: 10px;
  margin-bottom: -12px;
}
.c132-cart #order_one .ant-radio-inner::after {
  height: 10px;
  width: 10px;
}
.c132-cart #order_one .notice_pay_red {
  color: #FF0000;
  font-size: 13px;
  margin-left: 66px;
}
.c132-cart #order_one .ant-radio-wrapper {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .c132-cart #order_one .font_title_1 {
    font-size: 12px;
  }
  .c132-cart #order_one .ant-radio-wrapper {
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
  }
  .c132-cart #order_one .notice_pay_red {
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
  }
  .c132-cart #order_one .font_title_2 {
    font-size: 8px;
  }
  .c132-cart #order_one .order_title_1 {
    font-size: 12px;
    height: 60px;
  }
  .c132-cart #order_one .buypageForm__dt_2 {
    margin-left: 0px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    line-height: 1.3;
    padding-right: 1.2em;
    margin: 0.2em 0;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .c132-cart #order_one .margin_left_15 {
    margin-left: 0px;
    margin-top: 20px;
  }
  .c132-cart #order_one .margin_left_30 {
    margin-left: 1px;
    margin-top: 20px;
    text-align: center;
  }
  .c132-cart #order_one .buypageForm__dd_2 {
    margin-top: 20px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    width: calc(100% - 7em);
  }
  .c132-cart #order_one .buypageForm__dd_phone {
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    width: calc(100% - 7em);
  }
  .c132-cart #order_one .buypageForm__dt_email {
    margin-top: 20px;
    width: 6.2em;
    margin-left: 0px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
  }
  .c132-cart #order_one .buypageForm__dd_email {
    margin-top: 20px;
    font-size: calc(0.75rem + 4 * (100vw - 320px) / 448);
    width: calc(100% - 7.2em);
  }
  .c132-cart #order_one .cashiers__inner {
    font-size: 12px;
  }
  .c132-cart #order_one .ant-select {
    font-size: 12px;
  }
  .c132-cart #order_one .u-inputSkin02 {
    font-size: 12px;
    height: 30px;
  }
}
.c132-cart #order_one .add_star:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.c132-cart #order_one .dialog-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
  color: #000;
}
.c132-cart #order_one .choose-container {
  width: 660px;
  height: 640px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -360px;
  background: #fff;
  padding: 35px 20px 20px 25px;
}
.c132-cart #order_one .close-choose {
  overflow: scroll;
}
.c132-cart #order_one .close-choose a {
  float: right;
}
.c132-cart #order_one .choose-ti {
  font-size: 15px;
  line-height: 30px;
}
.c132-cart #order_one .choose-ti img {
  width: 30px;
  float: left;
  margin-right: 5px;
}
.c132-cart #order_one .choose-list {
  margin: 35px 0px 0px 0px;
  padding: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #d7d7d7;
}
.c132-cart #order_one .choose-list li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 15px;
}
.c132-cart #order_one .choose-left {
  float: left;
  width: 76px;
  font-size: 15px;
  line-height: 32px;
}
.c132-cart #order_one .choose-right {
  float: left;
  width: calc(100%-76px);
  width: -webkit-calc(24%);
  width: -moz-calc(24%);
}
.c132-cart #order_one .choose-right a {
  padding: 0px 15px;
  line-height: 30px;
  border: solid 1px #999999;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
}
.c132-cart #order_one .choose-total {
  margin-top: 40px;
  font-size: 15px;
  line-height: 20px;
}
.c132-cart #order_one .choose-total span {
  color: #339e9d !important;
  font-weight: 600;
  font-size: 25px;
}
.c132-cart #order_one .choose-add {
  overflow: hidden;
  margin-top: 40px;
}
.c132-cart #order_one .choose-add a {
  width: 140px;
  line-height: 40px;
  display: inline-block;
  float: right;
  text-align: center;
  font-size: 14px;
  background: #339e9d;
  color: #fff;
  border-radius: 4px;
}
.c132-cart #order_one .scroll-y {
  overflow-y: hidden;
}
.c132-cart #order_one .booker-ti {
  font-size: 18px;
  text-align: center;
}
.c132-cart #order_one .book-code {
  font-size: 14px;
  padding: 25px 0px;
}
.c132-cart #order_one .book-de {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}
.c132-cart #order_one .book-de span {
  color: #ff0000;
  font-weight: 600;
}
.c132-cart #order_one .book-de a {
  color: #000;
}
.c132-cart #order_one .table-ti {
  font-size: 14px;
  margin-bottom: 20px;
}
.c132-cart #order_one .table-name {
  background: #f1f6f6;
  overflow: hidden;
}
.c132-cart #order_one .table-name div {
  float: left;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
.c132-cart #order_one .wid20 {
  width: 20%;
}
.c132-cart #order_one .wid60 {
  width: 60%;
}
.c132-cart #order_one .table-de ul {
  padding: 0px;
  margin: 0px;
}
.c132-cart #order_one .table-de ul li {
  list-style: none;
  border-bottom: solid 1px #cccccc;
  overflow: hidden;
  padding: 15px 0px;
}
.c132-cart #order_one .table-de ul li div {
  float: left;
  text-align: center;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}
.c132-cart #order_one .color-green {
  color: #339e9d;
}
.c132-cart #order_one .wid60 .table-all {
  font-size: 14px;
  line-height: 26px;
}
.c132-cart #order_one .line-78 {
  line-height: 78px;
}
.c132-cart #order_one .table-all span {
  width: 100px;
  display: inline-block;
  text-align: right;
}
.c132-cart #order_one .cancle {
  width: 160px;
  margin: 30px auto 0 auto;
  line-height: 40px;
  color: #fff;
  background: #339e9d;
  font-size: 14px;
  display: block;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.c132-cart #order_one .book-input {
  margin-top: 40px;
}
.c132-cart #order_one .book-input ul {
  margin: 0px;
  padding: 0px;
}
.c132-cart #order_one .book-input ul li {
  list-style: none;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 20px;
}
.c132-cart #order_one .book-input ul li span {
  color: #ff0000;
}
.c132-cart #order_one .book-left {
  float: left;
  width: 170px;
  line-height: 34px;
}
.c132-cart #order_one .book-right {
  float: left;
}
.c132-cart #order_one .book-right input {
  width: 285px;
  height: 30px;
}
.c132-cart #order_one .my-sub {
  width: 230px;
  height: 40px;
  background: #339e9d;
  border-radius: 5px;
  margin: auto;
  display: inline-block;
  border: none;
  color: #fff;
}
.c132-cart #order_one .text-center {
  text-align: center;
}
@media (max-width: 768px) {
  .c132-cart #order_one .choose-container {
    width: calc(90%);
    height: 80%;
    margin-left: -45%;
  }
  .c132-cart #order_one .choose-ti {
    font-size: 14px;
    line-height: 15px;
  }
  .c132-cart #order_one .choose-right {
    width: 100%;
  }
  .c132-cart #order_one .choose-right a {
    padding: 0px 10px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .c132-cart #order_one .choose-list {
    margin-top: 15px;
    padding-bottom: 10px;
  }
  .c132-cart #order_one .choose-list li {
    margin-bottom: 5px;
  }
  .c132-cart #order_one .choose-total {
    margin-top: 20px;
  }
  .c132-cart #order_one .choose-add {
    margin-top: 20px;
  }
  .c132-cart #order_one .table-all span {
    width: 60px;
    display: inline-block;
    text-align: right;
  }
  .c132-cart #order_one .scroll-y {
    overflow-y: scroll;
  }
  .c132-cart #order_one .book-code {
    font-size: 12px;
  }
}
.c132-cart .member-pay {
  width: 100%;
}
.c132-cart .member-pay header div {
  text-align: center;
}
.c132-cart .member-pay header div span {
  font-size: 1.1em;
  color: #666;
  padding: 0px 50px;
  display: inline-block;
  line-height: 30px;
}
.c132-cart .member-pay-current {
  font-weight: 600;
  color: #339E9D !important;
}
.c132-cart .memberpay-main {
  margin-top: 30px;
}
.c132-cart .pay-main-ti {
  font-size: 19px;
  font-weight: 600;
  line-height: 50px;
  padding-left: 30px;
  background: #f1f6f6;
  -moz-box-shadow: 0px 0px 8px #CDDFDC;
  -webkit-box-shadow: 0px 0px 8px #CDDFDC;
  box-shadow: 0px 0px 8px #CDDFDC;
}
.c132-cart .pay-main-de ul {
  padding: 30px;
}
.c132-cart .pay-main-de ul li {
  font-size: 15px;
  margin-bottom: 20px;
  overflow: hidden;
}
.c132-cart .pay-main-de ul li span {
  float: left;
}
.c132-cart .pay-main-de ul li span:first-child {
  width: 120px;
  display: inline-table;
}
.c132-cart .member-gopay {
  text-align: center;
  margin-top: 50px;
}
.c132-cart .member-gopay a {
  font-size: 15px;
  line-height: 50px;
  color: #fff;
  padding: 0px 200px;
  background: #339e9d;
  border-radius: 5px;
  display: inline-block;
}
.c132-cart .phone_show_box {
  display: none;
}
@media (max-width: 768px) {
  .c132-cart .member-pay header div span {
    padding: 0px 10px;
  }
  .c132-cart .pay-main-de ul li span:last-child {
    width: calc(100% - 120px);
    width: -webkit-calc(-20%);
    width: -moz-calc(-20%);
    display: inline-block;
  }
  .c132-cart .member-gopay a {
    padding: 0px;
    width: 100%;
  }
  .c132-cart .phone_show_box {
    display: block;
  }
}
.c132-cart .member-ad a img {
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.c132-cart .pay-main-li {
  margin-top: 10px;
}
.c132-cart .pay-main-li ul li {
  box-sizing: border-box;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: solid 1px #d7d7d7;
  overflow: hidden;
  text-align: center;
}
.c132-cart .pay-main-li ul li:first-child div {
  font-size: 15px !important;
  font-weight: 600;
  line-height: 44px;
}
.c132-cart .pay-main-li ul li:last-child {
  border-bottom: solid 2px #797979;
}
.c132-cart .pay-main-li ul li:not(:first-child) {
  padding: 25px 0px;
}
.c132-cart .pay-main-li ul li div {
  text-align: center;
  font-size: 13px;
  color: #848484;
}
.c132-cart .main-li001 {
  width: 6%;
  float: left;
}
.c132-cart .main-li002 {
  width: 25%;
  float: left;
  padding-left: 20px;
}
.c132-cart .main-li003 {
  width: 15%;
  float: left;
}
.c132-cart .main-li004 {
  width: 30%;
  float: left;
}
.c132-cart .main-li005 {
  width: 15%;
  float: left;
}
.c132-cart .main-li006 {
  width: 9%;
  float: left;
}
.c132-cart .music-ti {
  text-align: left !important;
}
.c132-cart .music-ti a {
  text-decoration: underline;
  padding-left: 20px;
  font-weight: 600;
}
.c132-cart .music-by {
  padding-left: 20px;
  text-align: left;
}
.c132-cart .choose-use {
  text-decoration: underline;
}
.c132-cart .pc_show_img {
  display: block;
}
.c132-cart .phone_show_img {
  display: none;
}
@media (max-width: 768px) {
  .c132-cart .pay-main-ti {
    font-size: 12px;
  }
  .c132-cart .pc_show_img {
    display: none;
  }
  .c132-cart .phone_show_img {
    display: block;
  }
  .c132-cart .pay-main-li ul li:first-child div:first-child {
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }
  .c132-cart .pay-main-li ul li:first-child div:not(:first-child) {
    display: none;
  }
  .c132-cart .pay-main-li ul li .phone_size_style {
    font-size: calc(0.625rem + (200vw - 640px)/448);
    color: #333;
  }
  .c132-cart .subscribe_use_p {
    font-size: 12px;
  }
  .c132-cart .subscribe_use_num {
    font-size: 12px;
  }
  .c132-cart .subscribe_use {
    height: 55px;
    width: 94%;
  }
  .c132-cart .music-img {
    display: none;
  }
  .c132-cart .main-li001 {
    width: 15%;
  }
  .c132-cart .main-li002 {
    width: 60%;
    text-align: left !important;
  }
  .c132-cart .main-li002 div {
    text-align: left !important;
  }
  .c132-cart .music-ti a {
    padding-left: 0px;
  }
  .c132-cart .music-by {
    padding-left: 0px;
  }
  .c132-cart .music-phone {
    margin-top: 10px;
  }
  .c132-cart .main-li003 {
    display: none;
  }
  .c132-cart .main-li004 {
    display: none;
  }
  .c132-cart .main-li005 {
    float: right;
  }
  .c132-cart .main-li006 {
    width: 15%;
    float: right;
  }
  .c132-cart .buypageMusic {
    background-color: #fff;
  }
  .c132-cart .wechat_code_phone {
    display: none;
  }
  .c132-cart .wechat_code_phone2 {
    display: block;
  }
}
.c132-cart .pay-main-li ul li .notice_problem {
  clear: both;
  position: absolute;
  top: 80%;
  left: 8%;
  color: red;
}
.c132-cart .sub_style {
  height: 300px;
  width: 630px;
  padding-top: 18px;
}
.c132-cart .sub_style_scope {
  overflow: hidden;
}
.c132-cart .dialog__title {
  font-size: 15px;
  text-align: center;
}
.c132-cart .dialog__title img {
  width: 45px;
  height: 45px;
  margin-right: 20px;
  position: relative;
  top: 10px;
}
.c132-cart .dialog__txt {
  text-align: center;
}
.c132-cart .dialog__txt a :hover {
  background: #339e9d;
  color: #fff;
}
.c132-cart .dialog__txt a {
  width: 116px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  background: #fff;
  border: 1px solid #339e9d;
  color: #339e9d;
  border-radius: 4px;
}
.c132-cart .add_car_success {
  height: auto;
}
.c132-cart .sub_margin_top {
  margin-top: 25px;
}
.c132-cart .margin_right_25 {
  margin-right: 8px;
  margin-top: 33px;
}
.c132-cart .nocishu_margin_top {
  margin-top: 70px;
}
