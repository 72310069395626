.c02b478-comanymakerlist .resultPagination {
  text-align: center;
  font-size: 2em;
}
.phone_show {
  display: none;
}
@media only screen and (max-width: 767px) {
  .phone_hide {
    display: none;
  }
  .wid20 {
    width: 25%;
  }
  .settlementWrapper-de .settlementWrapper-deti {
    padding-left: 7px;
    width: 25% !important;
    font-size: 12px;
  }
  .settlementWrapper-de .phone_show {
    display: table-cell;
    padding-left: 15px;
    padding-right: 10px;
  }
  .export {
    padding: 0px 6px;
    line-height: 25px;
  }
  .export img {
    margin-top: 3px;
  }
  .settlementWrapper-de .phone_padding {
    padding-right: 6px;
  }
  .phone_clear {
    float: unset;
    width: 100%;
    margin-bottom: 10px;
  }
  .margin-left-5 {
    margin-left: 0px;
  }
}
